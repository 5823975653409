import { Link } from "@inertiajs/inertia-vue3"
import { defineComponent, computed, PropType } from "vue"

const common =
  "inline-flex items-center border items-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"

const variants = {
  white: "border-gray-400 rounded text-primary-700 bg-white hover:bg-primary-50 focus:ring-primary-500",
  secondary: "border-transparent rounded text-secondary-200 bg-secondary-800 hover:bg-secondary-600 focus:ring-secondary-500",
  primary: "border-transparent rounded shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-500",
  dark: "border-transparent hover:bg-primary-800 bg-gray-900 focus:ring-white text-white",
  link: "border-transparent text-primary-600 hover:text-primary-500 focus:ring-primary-500",
}

const active = {
  white: "border-white",
  secondary: "border-white",
  primary: "border-white",
  dark: "border-white",
  link: "border-primary-500",
}

const sizes = {
  sm: "px-2.5 py-1.5 text-xs rounded",
  md: "px-3 py-2 text-base rounded-md",
  lg: "px-6 py-3 text-base rounded-md",
}

export default defineComponent({
  name: "Button",
  props: {
    variant: {
      type: String as PropType<keyof typeof variants>,
      default: "primary",
      validator: (value: string) => ["white", "primary", "secondary", "dark", "link"].includes(value),
    },
    size: {
      type: String as PropType<keyof typeof sizes>,
      default: "md",
      validator: (value: string) => ["sm", "md", "lg"].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    // to -> Wird damit ein Inertia Link
    to: {
      type: String,
      default: null,
    },
    // href -> wird ein normaler Link
    href: {
      type: String,
      default: null,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const variantClasses = computed(() => {
      const classes = [common, variants[props.variant]]
      if (props.size) classes.push(sizes[props.size])
      if (props.active) classes.push(active[props.variant])
      let out = classes.join(" ")
      if (props.wide) {
        out = out.replace(/px-[\d\.]+/, "px-16")
      }
      return out
    })
    return { variantClasses }
  },
  render() {
    const slots = this.$slots
    const slot = {
      default: () => (slots.default ? slots.default() : null),
    }
    if (this.to) {
      return (
        <Link href={this.to} disabled={this.disabled ? true : undefined} class={this.variantClasses}>
          {() => slot.default()}
        </Link>
      )
    }
    if (this.href) {
      return (
        <a href={this.href} class={this.variantClasses} disabled={this.disabled ? true : undefined}>
          {() => slot.default()}
        </a>
      )
    }
    return (
      <button disabled={this.disabled} class={this.variantClasses} role="button">
        {() => slot.default()}
      </button>
    )
  },
})
